import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="modal"
export default class extends Controller {
  static targets = ["modalDialog", "input", "submitButton"];
  static values = { type: String };

  connect() {
    this.modalDialogTarget.classList.add("hidden");
    this.submitButtonTarget.disabled = true;
  }

  show() {
    this.inputTarget.value = "";
    this.modalDialogTarget.classList.remove("hidden");
  }

  hide() {
    this.modalDialogTarget.classList.add("hidden");
  }

  validate() {
    const inputValue = this.inputTarget.value.trim().toLowerCase();
    const expectedValue = `delete ${this.typeValue.toLowerCase()}`;

    if (inputValue === expectedValue) {
      this.enableButton();
    } else {
      this.disableButton();
    }
  }

  enableButton() {
    this.submitButtonTarget.disabled = false;
    this.submitButtonTarget.classList.remove(
      "bg-gray-400",
      "cursor-not-allowed"
    );
    this.submitButtonTarget.classList.add("bg-red-600", "hover:bg-red-700");
  }

  disableButton() {
    this.submitButtonTarget.disabled = true;
    this.submitButtonTarget.classList.remove("bg-red-600", "hover:bg-red-700");
    this.submitButtonTarget.classList.add("bg-gray-400", "cursor-not-allowed");
  }
}
